<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-23 16:00:31
 * @LastEditTime: 2021-04-13 16:01:31
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <!-- 组织结构 -->
  <div class="framework">
    <LogoTitle msg="组织结构"></LogoTitle>
    <RichArticle :html="html"></RichArticle>
  </div>
</template>

<script>
export default {
  data () {
    return {
      html: ''
    }
  },
  created () {
    this.getDetail();
  },
  methods: {
    getDetail () {
      let params = {
        categoryId: '1376790496357289986'
      }
      this.$api.getCategoryDetail(params).then(res => {
        if (res.data.data) {
          this.html = res.data.data.contentDetails;
        }
      })
    }
  },
}
</script>

<style scoped lang='scss'>
</style>